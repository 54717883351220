:root {
  --colorMain: #5a20cb;
  --colorSecondary: #202020;
  --colorBg: black;
  --colorDetails: white;
}

* {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: normal;
  color: var(--colorDetails);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#App {
  padding: 40px;
  background-color: var(--colorBg);
  height: 100vh;
  /* width: calc(100% - 300px); */
  width: 100%;
  position: fixed;
  right: 0;
  overflow-y: auto;
}
.page {
  margin-inline: auto;
}

.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--colorSecondary);
}
.navigation nav {
  width: fit-content;
  display: flex;
  justify-content: space-evenly;
  padding: 15px 20px;
  gap: 40px;
  max-width: 1024px;
  margin-inline: auto;
}
.navigation nav a {
  width: fit-content;
  color: white;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  font-size: 24px;
  transition: 0.25s;
}
.navigation nav a.current {
  border-color: white;
}
.navigation nav a svg {
  width: 32px;
  height: 32px;
}

/* Titles */
h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 42px;
  line-height: 1;
}
h2 {
  font-size: 22px;
  line-height: 1.25;
}
h3 {
  font-size: 18px;
  line-height: 1.5;
}

/* Links */
a {
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 2px solid var(--colorMain);
  width: fit-content;
  transition: 0.25s;
}
a span {
  font-size: 18px;
}
a:hover {
  color: var(--colorMain);
}
.link-with-icon {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.link-with-icon svg {
  width: 20px;
  height: 20px;
}

/* Buttons */
button {
  border: 2px solid var(--colorMain);
  background: var(--colorMain);
  padding: 10px 40px;
  color: white;
  border-radius: 60px;
  cursor: pointer;
  transition: 0.25s;
}
button:hover {
  background: transparent;
  color: var(--colorMain);
}

/* Rows and columns */
.row {
  display: flex;
  gap: 20px;
}
.column {
  width: 50%;
}

/* Filters */
.filters > div {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  user-select: none;

  transition: 0.25s;
}
.filters > div.current {
  border-color: var(--colorMain);
  color: var(--colorMain);
  /* font-weight: 1.1; */
}
.hidden {
  display: none;
}

/* Projects */
.services-grid,
.projects-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.loop-service,
.loop-project {
  width: calc(100% - 50% - 10px);
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 20px;
  transition: 0.25s;
}
.loop-service:hover,
.loop-project:hover {
  border-color: var(--colorMain);
}
.loop-service h2,
.loop-project h2 {
  font-weight: normal;
  font-size: 28px;
  margin-bottom: 5px;
}
.loop-service h3,
.loop-project h3 {
  font-weight: normal;
}
.coming-soon {
  color: gray;
}
/* .loop-project h2 {
  color: var(--colorMain);
} */

/* Components */
.text-with-image {
  display: flex;
  gap: 40px;
  align-items: center;
}
.text-with-image.reverse {
  flex-direction: row-reverse;
}
.text-with-image > * {
  width: 50%;
}
.text-with-image-image {
  display: flex;
}
.text-with-image img {
  max-width: clamp(200px, 100%, 400px);
  margin-inline: auto;
  width: 100%;
  max-height: 250px;
}
.text-with-image h2 {
  font-size: 32px;
}

@media (min-width: 1025px) {
  .mobile-only {
    display: none;
  }
}

/* Mobile styles */
@media (max-width: 1024px) {
  .desktop-only {
    display: none;
  }
  #App {
    width: 100%;
    position: relative;
    padding: 20px;
  }
  * {
    font-size: 14px;
  }
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 14px;
  }
  .row {
    flex-direction: column;
    gap: 10px;
  }
  .column {
    width: 100%;
  }
  .navigation {
    top: unset;
    bottom: 0;
    height: auto;
    width: 100%;
  }
  .navigation nav {
    width: 100%;
    flex-direction: row;
    padding-block: 7px;
  }
  .navigation nav a svg {
    width: 24px;
    height: 24px;
  }
  .navigation nav a {
    font-size: 16px;
  }
  .filters {
    flex-direction: row;
  }
  .loop-service,
  .loop-project {
    width: 100%;
    padding: 10px;
  }
  .loop-service h2,
  .loop-project h2 {
    margin-bottom: 5px;
    font-size: 22px;
  }
  .text-with-image {
    flex-direction: column !important;
  }
  .text-with-image > * {
    width: 100%;
  }
  .text-with-image-image {
    display: flex;
  }
  .text-with-image img {
    max-width: clamp(200px, 100%, 300px);
  }
  .text-with-image h2 {
    font-size: 24px;
  }
}
